import { UserRole } from '../helpers/authHelper';

/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const firebaseConfig = JSON.parse(process.env.CP_FIREBASE);

// export const firebaseConfig = {
//   apiKey: 'AIzaSyDry26NLeGjLQLaIwUxR0PPy0r1cJNJ2JA',
//   authDomain: 'fexty-dev.firebaseapp.com',
//   databaseURL: 'https://fexty-dev.firebaseio.com',
//   projectId: 'fexty-dev',
//   storageBucket: 'fexty-dev.appspot.com',
//   messagingSenderId: '563432608585',
//   appId: '1:563432608585:web:d7facc6963ea18229dc098',
//   measurementId: 'G-JLCXX8D4F4',
// };

export const currentUser = {
  role: UserRole.Admin,
};

export const adminRoot = '/app';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/pages/miscellaneous/search`;
export const servicePath = 'https://api.coloredstrategies.com';

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.bluenavy';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
